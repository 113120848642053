<template>
    <div class="content_container sign_up">
        <h1>회원가입</h1>
        <p>
            아직 계정이 없으신가요?<br />
            회원가입 후 편리하게 거래처를 찾으실 수 있습니다.<br />
            원하시는 가입 수단을 선택해주세요.
        </p>
        <div class="wrapper">
            <div class="menu_sign_up">
                <a href="https://manager.meta-buysell.com/google/signin" class="btn_sign_up">
                    <img src="@/assets/images/login/ci_google.svg" alt="구글 ci" />
                    <p>Google로 회원가입</p>
                </a>
                <a href="https://manager.meta-buysell.com/naver/signin" class="btn_sign_up">
                    <img src="@/assets/images/login/ci_naver.svg" alt="네이버 ci" />
                    <p>네이버로 회원가입</p>
                </a>
                <a href="https://manager.meta-buysell.com/kakao/signin" class="btn_sign_up">
                    <img src="@/assets/images/login/ci_kakao.svg" alt="카카오 ci" />
                    <p>카카오로 회원가입</p>
                </a>
                <router-link to="/signupemail" class="btn_sign_up">
                    <img src="@/assets/images/login/icon_email.svg" alt="이메일" />
                    <p>이메일로 회원가입</p>
                </router-link>
            </div>

            <div class="line"></div>

            <div class="right">
                <swiper v-if="this.ad.length <= 1" v-bind="swiperOptionDefault">
                    <swiper-slide v-if="ad.length == 0">
                        <div class="image_wrapper">
                            <img src="@/assets/images/common/no_image.svg" />
                        </div>
                        <div class="user">
                            <h1>광고가 없습니다.</h1>
                        </div>
                    </swiper-slide>
                    <swiper-slide v-else>
                        <div class="image_wrapper">
                            <img :src="ad[0].path+ad[0].save_name" :alt="ad[0].original_name" />
                        </div>
                        <div v-if="ad[0].division_title == '판매자'" class="user">
                            <h1>{{ad[0].name}}</h1>
                            <p><span class="label_seller">판매자</span>{{ad[0].name}}</p>
                            <h2>카테고리 : <span>{{ad[0].category[0].category + (ad[0].category.length>1 ? ` 외${ad[0].category.length - 1}` : '') }}</span></h2>
                            <button @click="this.$router.push(`/seller/detail/${ad[0].pk}`)" class="sub_button">바로가기</button>
                        </div>
                        <div v-else class="user">
                            <h1>{{ad[0].product_name}}</h1>
                            <p><span class="label_provider">공급자</span>{{ad[0].name}}</p>
                            <h2>카테고리 : <span>{{ad[0].category}}</span></h2>
                            <button @click="this.$router.push(`/product/detail/${ad[0].pk}`)" class="common_button">바로가기</button>
                        </div>
                    </swiper-slide>
                </swiper>
                <swiper v-else v-bind="swiperOption">
                    <swiper-slide v-for="(item, i) in this.ad" :key="i">
                        <div class="image_wrapper">
                            <img :src="item.path+item.save_name" :alt="item.original_name" />
                        </div>
                        <div v-if="item.division_title == '판매자'" class="user">
                            <h1>{{item.name}}</h1>
                            <p><span class="label_seller">판매자</span>{{item.name}}</p>
                            <h2>카테고리 : <span>{{item.category[0].category + (item.category.length>1 ? ` 외${item.category.length - 1}` : '') }}</span></h2>
                            <button @click="this.$router.push(`/seller/detail/${item.pk}`)" class="sub_button">바로가기</button>
                        </div>
                        <div v-else class="user">
                            <h1>{{item.product_name}}</h1>
                            <p><span class="label_provider">공급자</span>{{item.name}}</p>
                            <h2>카테고리 : <span>{{item.category}}</span></h2>
                            <button @click="this.$router.push(`/product/detail/${item.pk}`)" class="common_button">바로가기</button>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
  <SubFooter />
</template>

<script>
import SubFooter from "@/components/SubFooter";
import { fetchData } from "@/utils/fetchData";

import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([ Autoplay]);

export default {
    mixins:[ fetchData ],
    components: {
        Swiper,
        SwiperSlide,
        SubFooter,
    },
    created(){
        this.fetchAdData();
    },
    data(){
        return {
            ad : [],
            swiperOption: {
                // speed: 600,
                loop: true,
                allowTouchMove: false,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
            },
            swiperOptionDefault: {
                allowTouchMove: false,
            },
            userAgent : navigator.userAgent.toLowerCase(),
            // wao: null,
            // webkit : null,
        }
    },
    methods: {
        fetchAdData(){
            this.fetchData('/getBasic?page=signup', (data)=>{
                this.ad = data.ad;
            })
        },
        // googleLogin(){
        //     const url = 'https://api.ownerapp.co.kr/login/getToken';
        //     this.axios.get(url)
        //     .then(res=>{
        //         if (window.wao) {
        //         window.wao.googleLogin(res.data.result);
        //         }
        //         if (window.webkit) {
        //             window.webkit.messageHandlers.wao.postMessage({
        //                 type: 'googleLogin',
        //                 value: {
        //                     't' : res.data.result
        //                 }
        //             });
        //         }
        //     });
        // },
        // appleLogin(){
        //     const url = 'https://api.ownerapp.co.kr/login/getToken';
        //     this.axios.get(url)
        //     .then(res=>{
        //         if (window.webkit) {
        //             window.webkit.messageHandlers.wao.postMessage({
        //                 type: 'appleLogin',
        //                 value: {
        //                     't' : res.data.result
        //                 }
        //             });
        //         }
        //     });
        // }
    }
};
</script>

<style scoped src="@/assets/css/layout_login.css">
</style>